<template lang="pug">
    .create-page
        .wrapper
            .background-wrapper
            .create-container
                p.title-3 {{ $t('create') }}
                .create-entities-container
                    .entity(
                        v-for="(module, index) in modules",
                        :key="'create-actions-' + index",
                        v-if="modules && module.createRoute",
                        @click="loadModal(module); $store.commit('search/resetWrapper')"
                    )
                        button.button.rounded
                            img(:src="module.icon")
                        p.title-6 {{ $t(module.creationLabel) }}

            button.button.rounded.dark(@click="$router.go(-1)")
                img(src="@/assets/icon/close-icon.svg")
</template>

<script lang="ts">
import Vue from "vue";
import Modules from "@/applicationSettings/Modules.js";

export default Vue.extend({
    data() {
        return {
            modules: null,
        };
    },

    methods: {
        loadModal(module) {
            this.$router.push(module.createRoute);
        },
    },

    mounted() {
        Modules.getModules().then((modules) => {
            this.modules = modules;
        });
    },
});
</script>

<style lang="scss" scoped>
.create-page {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 4000;
    background: transparent !important;

    .background-wrapper {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background: var(--modal-overlayer-color);
    }

    .wrapper {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        z-index: 4001;

        .create-container {
            width: calc(100vw - 32px);
            position: absolute;
            left: 16px;
            bottom: 35vh;
            z-index: 4003;

            .title-3 {
                color: white;
                text-align: center;
                margin-bottom: 30px;
            }

            .create-entities-container {
                display: flex;

                .entity {
                    width: calc(100% / 3);

                    .button {
                        width: 45px;
                        height: 45px;
                        border: none;
                        background: white;
                        display: flex;
                        align-items: center;
                        align-content: center;
                        padding: inherit;
                        margin-left: auto;
                        margin-right: auto;
                        padding-top: 0 !important;

                        img {
                            width: 24px;
                            height: 24px;
                            margin-left: auto;
                            margin-right: auto;
                            filter: var(--svg-filter-text-color);
                        }
                    }

                    p {
                        text-align: center;
                        margin-top: 7px;
                        color: white;
                    }
                }
            }
        }

        button.dark {
            position: absolute;
            bottom: 18vh;
            left: calc(50vw - 28px);
            width: 56px;
            height: 56px;
            padding: 14px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                z-index: 4003;
                filter: var(--svg-filter-white-color);
                width: 24px;
                height: 24px;
            }
        }
    }
}
</style>